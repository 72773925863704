import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
//import Tabs from "@material-ui/core/Tabs";
//import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LoginScreen from "../pages/LoginScreen";
//import RegisterScreen from "../pages/RegisterScreen";
import Container from "@material-ui/core/Container";
import { Grid } from "@mui/material";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import "./index.css";


const SignInOutContainer = ({ history }) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    value === 1 ? history.push("/login") : history.push("/register");
  };

  const paperStyle = {
    width: 340,
  };

  /* function TabPanel(props) {
    const { children, value, index, ...other } = props; 

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }*/

  return (
    <div
      className="imageStyle"
      style={{
        backgroundImage: 'url("/IMDRPicture.webp")',
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
      }}
    >
      <div
        className="imagelogo"
        style={{

          backgroundImage: 'url("/Logo.webp")',

          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundColor: "white",

        }}>
      </div>

      <Grid alignItems={"center"} container justifyContent={"center"}>
        <Paper style={paperStyle}>
          {/*  <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            
            <Tab label="Sign In" />
            <Tab label="Sign Up" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <LoginScreen handleChange={handleChange} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <RegisterScreen handleChange={handleChange} />
          </TabPanel> */}
          <LoginScreen handleChange={handleChange} />
        </Paper>
        <AppBar position="sticky" style={{ top: '100%' }} >
          <Container maxWidth="md">
            <Toolbar disableGutters={true} style={{ justifyContent: "center" }}>
              <Typography variant="body1" color="inherit" align="center">
                Copyright © IMDR 2021
              </Typography>
            </Toolbar>
          </Container>
        </AppBar>
      </Grid>
    </div >


  );
};

export default SignInOutContainer;
