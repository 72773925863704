import React from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import HelpScreenUser from "./HelpScreenUser";
import HelpScreenAdmin from "./HelpScreenAdmin";

const Help = () => {
  let history = useHistory();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }
  }, [history, userInfo]);

  return (
    <>
      {userInfo && userInfo.isAdmin ? <HelpScreenAdmin /> : <HelpScreenUser />}
    </>
  );
};

export default Help;
