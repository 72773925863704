import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

const HelpScreenAdmin = () => {
  let history = useHistory();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }
  }, [history, userInfo]);

  const itemData = [
    {
      img: "/Slide1.webp",
    },
    {
      img: "/Slide9.webp",
    },
    {
      img: "/Slide10.webp",
    },
    {
      img: "/Slide11.webp",
    },
    {
      img: "/Slide7.webp",
    },
    {
      img: "/Slide8.webp",
    },
  ];

  return (
    <Grid>
      <h2>
        <center>We Are Here To Help You!</center>
      </h2>
      <hr />
      <center>
        <ImageList
          sx={{ width: "70%", height: "70%" }}
          rows={7}
          cols={1}
          gap={50}
        >
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=4 4x`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </center>
    </Grid>
  );
};

export default HelpScreenAdmin;
